import React from 'react';
import {
  ConversationHeader,
  Button,
  MessageSeparator,
  Avatar,
  TypingIndicator,
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  InputToolbox,
} from "@chatscope/chat-ui-kit-react";
import chatIcon from '../assets/uinel-assets/avatars/female1-front-zoom-2.png';
import '../themes/default/main.scss'; // SASS is only used to override chat styles https://github.com/chatscope/chat-ui-kit-styles/issues/5
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons'
import { consoleLogCustom, createNewConversation, getLLMResponse, stringifyToday } from './Utils';
import { useTranslation } from 'react-i18next';
import { ReducedAvailableLanguages } from './AvailableLanguages';


function BubbleChat(props) {

  const [messageInputValue, setMessageInputValue] = React.useState("");
  const [lastMessageInputValue, setLastMessageInputValue] = React.useState("");
  const [messageList, setMessageList] = React.useState([]);
  const [messageIndex, setMessageIndex] = React.useState(1);
  const [lastSender, setLastSender] = React.useState(false); // false: AI, true: User
  const [showTyping, setShowTyping] = React.useState(false);
  const [chatHistory, setChatHistory] = React.useState([]);
  const [bubbleState, setBubbleState] = React.useState(true);
  const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const [color, setColor] = React.useState('indigo');
  const [conversationId, setConversationId] = React.useState(null);
  const [languageNoRegion, setLanguageNoRegion] = React.useState(getDefaultLanguage().split('-')[0]);

  const { t, i18n } = useTranslation();

  // React useEffect hook that will fire up
  // when "color" changes
  React.useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    setColor(props.color);
    const root = document.documentElement;
    let colorIn, colorOut;
    switch (props.color) {
      case 'indigo':
        colorIn = "#818cf8bf";
        colorOut = "#4f46e5bf";
        break;
      case 'blue':
        colorIn = "#60a5fabf";
        colorOut = "#2563ebbf";
        break;
      case 'green':
        colorIn = "#4ade80bf";
        colorOut = "#16a34abf";
        break;
      case 'yellow':
        colorIn = "#facc15bf";
        colorOut = "#ca8a04bf";
        break;
      case 'orange':
        colorIn = "#fb923cbf";
        colorOut = "#ea580cbf";
        break;
      case 'red':
        colorIn = "#f87171bf";
        colorOut = "#dc2626bf";
        break;
      default:
        colorIn = "#818cf8bf";
        colorOut = "#4f46e5bf";
        break;
    }
    root?.style.setProperty("--chat-incoming-message-color", colorIn);
    root?.style.setProperty("--chat-outgoing-message-color", colorOut);
  }, [props.color]);


  // Hook that will fire up when dashboard mode changes
  React.useEffect(() => {
    if (props.dashboardMode === true) {
      setBubbleState(false); // Hide bubble initially when loading chat in dashboard mode
    }
  }, [props.dashboardMode]);


  // Hook that will fire up when conversationId changes
  React.useEffect(() => {
    async function waitToHandleResponse() {
      if (conversationId != null) {
        await handleResponse();
      }
    }
    waitToHandleResponse();
  }, [conversationId]);

  // Hook that will fire up when messageList changes
  React.useEffect(() => {
    // Check if last message is from user
    if (messageList.length > 0) {
      const lastMessage = messageList[messageList.length - 1];
      if (lastMessage.props.model.direction === "outgoing") {
        continueHandleSend();
      }
    }
  }, [messageList]);

  function handleSend() {

    setMessageList([...messageList,
    <Message
      key={messageIndex}
      model={{
        message: messageInputValue,
        sentTime: "just now",
        sender: "Joe",
        direction: "outgoing",
        position: "single", // lastSender ? "normal" : "first",
      }}
    >
      {/* <Avatar src={chatIcon} name="Joe" /> */}
    </Message>]);

    // Save message and clear input
    setLastMessageInputValue(messageInputValue);
    setMessageInputValue("");

    // Continues in continueHandleSend() when messagelist is updated

  }

  async function continueHandleSend() {

    setLastSender(true);

    setMessageIndex(messageIndex + 1);

    // Handle response
    setShowTyping(true);

    if (conversationId == null) {
      // If conversationId is null, we need to start a new conversation
      consoleLogCustom('Starting new conversation');
      const result = await createNewConversation(
        props.chatbotId,
      )
      consoleLogCustom(result);
      setConversationId(result);
      // Hook calls handleResponse() when conversationId is updated
    } else {
      // If conversationId is not null, we can continue the conversation
      await handleResponse();
    }

  }

  async function handleResponse() {

    const response = await getResponse(lastMessageInputValue);

    setShowTyping(false);

    setMessageList([
      ...messageList,
      // <Message
      //   key={messageIndex}
      //   model={{
      //     message: messageInputValue,
      //     sentTime: "just now",
      //     sender: "Joe",
      //     direction: "outgoing",
      //     position: lastSender ? "normal" : "first",
      //   }}
      // >
      //   {/* <Avatar src={chatIcon} name="Joe" /> */}
      // </Message>,
      <Message
        key={messageIndex}
        model={{
          message: response,
          sentTime: "just now",
          sender: "Joe",
          direction: "incoming",
          position: "single", // lastSender ? "normal" : "first",
        }}
      >
        <Avatar src={chatIcon} name="Joe" />
      </Message>
    ]);

    setLastSender(false);

    setMessageIndex(messageIndex + 1);
  }

  async function getResponse(query) {
    consoleLogCustom('Updated version');
    const data = await getLLMResponse(
      query,
      props.chatbotId,
      props.parent,
      'chat',
      null, // Avatar identity is not used in chat
      conversationId,
      props.language, // Not used in chat type
      chatHistory
    )
    consoleLogCustom(data);
    // Add new entry to chat_history
    setChatHistory([...chatHistory, { 'question': query, 'answer': data['answer'] }]);
    return data['answer'];
  }

  function renderGearIcon() {
    return (
      <svg className="w-7 h-7 text-chatbotgray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  }

  function handleSettingsClick() {
    setSettingsMenuOpen(!settingsMenuOpen);
    // Get settingsMenu element
    const settingsMenu = document.getElementsByName('settingsMenu')[0];
    // If settingsMenu is open, remove hidden class
    if (!settingsMenuOpen) {
      settingsMenu.classList.remove('hidden');
    } else {
      settingsMenu.classList.add('hidden');
    }
    consoleLogCustom('settingsMenuOpen: ', settingsMenuOpen);
  }

  function getDefaultLanguage() {
    // Get language from props
    const defaultLanguage = props.language.split('-')[0] ?? 'en';
    // Check if language is available
    if (ReducedAvailableLanguages.filter((availableLanguage) => availableLanguage.value === defaultLanguage).length === 0) {
      consoleLogCustom('Language not available. Using selected global language');
      return i18n.language;
    } else {
      return defaultLanguage;
    }
  }

  function handleOnChangeLanguage(event) {
    setLanguageNoRegion(event.target.value);
    const languageSelector = document.getElementById('language');
    languageSelector.value = event.target.value;
    const savedMessage = document.getElementsByName('saved-message')[0];
    savedMessage.classList.remove('hidden');
    setTimeout(() => {
      savedMessage.classList.add('hidden');
    }, 2000);
    consoleLogCustom('language: ', event.target.value);
  }

  return (
    <div className={props.size + ' relative'}>
      <div className={(props.showBubble && bubbleState) ? 'rounded-full hover:scale-105 transition absolute bottom-2 right-4' : 'hidden'}>
        <button
          onClick={() => setBubbleState(!bubbleState)}
          className='focus:outline-none'
        >
          <img
            className=''
            src={props.showBubble === 'true' ? 'https://img.icons8.com/ios-filled/50/000000/chat.png' : 'https://img.icons8.com/ios-filled/50/000000/chat.png'}
            alt='Chat icon used to open chat'
          />
        </button>
      </div>
      <div className={(!props.showBubble || (props.showBubble && !bubbleState)) ? props.size : 'hidden'}>
        <MainContainer>
          <ChatContainer>
            <ConversationHeader>
              <Avatar src={chatIcon} name="Zoe" />
              <ConversationHeader.Content userName={t('chat_title', { lng: languageNoRegion })} info={t('chat_subtitle', { lng: languageNoRegion })} />
              <ConversationHeader.Actions>
                <Button onClick={handleSettingsClick} icon={renderGearIcon()} />
                {props.showBubble && (
                  <Button onClick={() => setBubbleState(!bubbleState)} icon={<FontAwesomeIcon className='mb-2 ml-1' icon={faWindowMinimize} />} />
                )}
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList typingIndicator={showTyping ? <TypingIndicator content="Processing" /> : <></>}>
              <MessageSeparator content={stringifyToday(languageNoRegion)} />
              <Message
                key='first-message'
                model={{
                  message: t('chat_welcome_message', { lng: languageNoRegion }),
                  sentTime: "just now",
                  sender: "Joe",
                  direction: "incoming",
                  position: "single",
                }}
              >
                <Avatar src={chatIcon} name="Joe" />
              </Message>
              {messageList}
            </MessageList >
            <MessageInput
              placeholder={t('chat_type_message_here', { lng: languageNoRegion })}
              value={messageInputValue}
              onChange={val => setMessageInputValue(val)}
              onSend={handleSend}
              attachButton={false} >
              <h1>My custom conversation header</h1>
            </MessageInput>
            <InputToolbox>
              <div className='text-sm'>
                Powered by
                {/* Open link in new tab */}
                <a
                  className='ml-1'
                  href="https://chattier.dev"
                  target='_blank'
                  rel="noreferrer" >
                  <b>Chattier</b>
                </a>
              </div>
            </InputToolbox>
          </ChatContainer>
        </MainContainer>
      </div>
      {/* Render settings menu screen on top of all previous elements */}
      <div
        name="settingsMenu"
        className={'hidden absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 z-[600] flex flex-col items-center justify-center'} >
        {/* Render button to close menu on the top right corner */}
        <button
          onClick={handleSettingsClick} // Close menu
          className='absolute top-4 right-4 flex flex-col items-center justify-center rounded-full bg-transparent w-6 h-6'>
          <svg className="w-6 h-6 text-gray-400 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {/* Render settings menu content */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-center">
            <h2 className="text-xl font-bold text-gray-900">{t('chatbot_language', { lng: languageNoRegion })}</h2>
            <select
              name="language"
              id="language"
              className="ml-4 w-40 h-10 rounded-md"
              defaultValue={getDefaultLanguage().split('-')[0]}
              onChange={handleOnChangeLanguage}
            >
              {ReducedAvailableLanguages.map((language) => {
                return (
                  <option value={language['value']} key={language['value']}>{t('languages_' + language['value'].replace('-', '_'), { lng: languageNoRegion, rgn: '(US)' })}</option>
                )
              })}
            </select>
          </div>
          <span
            className="hidden text-sm text-gray-500"
            name="saved-message">{t('chatbot_setting_saved', { lng: languageNoRegion })}</span>
        </div>
      </div>
    </div >
  );
}

BubbleChat.defaultProps = {
  showBubble: 'false',
};

export default BubbleChat;