import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getDomainFromUrl } from '../components/Domain';
import BubbleChat from '../components/BubbleChat';
import AvatarView from '../components/AvatarView';
import femaleAvatar1 from '../assets/uinel-assets/avatars/female1.glb';
import femaleAvatar2 from '../assets/uinel-assets/avatars/female2.glb';
import femaleAvatar3 from '../assets/uinel-assets/avatars/female3.glb';
import maleAvatar1 from '../assets/uinel-assets/avatars/male1.glb';
import maleAvatar2 from '../assets/uinel-assets/avatars/male2.glb';
import maleAvatar3 from '../assets/uinel-assets/avatars/male3.glb';
import femaleAvatar1Image from '../assets/uinel-assets/avatars/female1-image.png';
import femaleAvatar2Image from '../assets/uinel-assets/avatars/female2-image.png';
import femaleAvatar3Image from '../assets/uinel-assets/avatars/female3-image.png';
import maleAvatar1Image from '../assets/uinel-assets/avatars/male1-image.png';
import maleAvatar2Image from '../assets/uinel-assets/avatars/male2-image.png';
import maleAvatar3Image from '../assets/uinel-assets/avatars/male3-image.png';
import { consoleLogCustom, countTokens, getAvatarSizeInPixels, getBackendUrl, getChatOrAvatarSize, getChatSize } from '../components/Utils';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { AvailableLanguageModels, AvailableLanguages, getLanguageFromReducedLanguage } from '../components/AvailableLanguages';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../components/WarningIcon';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function UpdateChatbot(props) {

  const { t, i18n } = useTranslation();

  const [configStep, setConfigStep] = React.useState(0);
  const [targetUrl, setTargetUrl] = React.useState('');
  const [sitemapUrl, setSitemapUrl] = React.useState('');
  const [urls, setUrls] = React.useState([]);
  const [filteredUrls, setFilteredUrls] = React.useState([]);
  const [globalCheckboxChecked, setGlobalCheckboxChecked] = React.useState(false);
  const [chatbotName, setChatbotName] = React.useState('');
  const [chatbotDescription, setChatbotDescription] = React.useState('');
  const [chatbotInstructions, setChatbotInstructions] = React.useState(t('new_chatbot_instructions_eg'));
  const [chatbotLanguageModel, setChatbotLanguageModel] = React.useState('gpt-3.5-turbo-1106');
  const [chatbotLanguage, setChatbotLanguage] = React.useState(getLanguageFromReducedLanguage(i18n.language));
  const [textSource, setTextSource] = React.useState('');
  const [selectedUrls, setSelectedUrls] = React.useState([]);
  const [selectedUrlsCount, setSelectedUrlsCount] = React.useState(0);
  const [allowedDomainsCount, setAllowedDomainsCount] = React.useState(0);
  const [manualUrl, setManualUrl] = React.useState('');
  const [allowedDomain, setAllowedDomain] = React.useState('');
  const [allowedDomains, setAllowedDomains] = React.useState([]);
  const [chatbotType, setChatbotType] = React.useState('avatar');
  const [avatarIdentity, setAvatarIdentity] = React.useState('female1');
  const [dataSourceType, setDataSourceType] = React.useState('text');
  const [showBubble, setShowBubble] = React.useState(true);
  const [selectedChatSize, setSelectedChatSize] = React.useState('medium');
  const [selectedAvatarSize, setSelectedAvatarSize] = React.useState('medium');
  const [selectedColor, setSelectedColor] = React.useState('indigo');
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [isDiscoveringUrls, setIsDiscoveringUrls] = React.useState(false);
  const [instructionsTooLongWarning, setInstructionsTooLongWarning] = React.useState(false);
  const [instructionsEmptyWarning, setInstructionsEmptyWarning] = React.useState(false);
  const [nameEmptyWarning, setNameEmptyWarning] = React.useState(false);
  const [instructionsTokens, setInstructionsTokens] = React.useState(0);
  const [noSourcesWarning, setNoSourcesWarning] = React.useState(false);
  const [noDomainsWarning, setNoDomainsWarning] = React.useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Add constructor here
  React.useEffect(() => {
    async function loadChatbotData() {
      // If updating an existing chatbot, load the chatbot data
      if (!props.new) {
        // Load the chatbot data
        const chatbotId = searchParams.get('cid');
        if (chatbotId) {
          const chatbotRef = doc(db, "chatbots", chatbotId);
          const chatbotSnap = await getDoc(chatbotRef);
          if (chatbotSnap.exists()) {
            const chatbotObject = chatbotSnap.data();
            if (chatbotObject) {
              // Chatbot found
              setChatbotName(chatbotObject['name']);
              setChatbotDescription(chatbotObject['description']);
              setChatbotInstructions(chatbotObject['instructions'] ?? t('new_chatbot_instructions_eg'));
              setChatbotLanguageModel(chatbotObject['language_model'] ?? 'gpt-3.5-turbo-1106');
              setChatbotLanguage(chatbotObject['language'] ?? getLanguageFromReducedLanguage(i18n.language));
              setAllowedDomains(chatbotObject['allowed_domains']);
              setChatbotType(chatbotObject['chatbot_type']);
              setAvatarIdentity(chatbotObject['avatar_identity']);
              setDataSourceType(chatbotObject['data_source']);
              setSelectedChatSize(chatbotObject['chatbot_size']);
              setShowBubble(chatbotObject['chatbot_bubble'] === 'true');
              setSelectedColor(chatbotObject['chatbot_color']);
              setTextSource(chatbotObject['text_source']);
              const urlmaplist = []
              for (const url of chatbotObject['selected_urls']) {
                const urlmap = new Map()
                urlmap.set('url', url)
                urlmap.set('selected', true)
                urlmaplist.push(urlmap)
              }
              setUrls(urlmaplist);
              setFilteredUrls(urlmaplist);
            } else {
              // Chatbot not found
              navigate('/dashboard');
            }
          }
        } else {
          // No chatbot id specified
          consoleLogCustom('No chatbot id specified');
          navigate('/dashboard');
        }
      }
    }
    loadChatbotData();
  }, []);

  function getNameOfConfigStep(configStep) {
    switch (configStep) {
      case 0:
        return t('new_chatbot_step_1');
      case 1:
        return t('new_chatbot_step_2');
      case 2:
        return t('new_chatbot_step_3');
      case 3:
        return t('new_chatbot_step_4');
    }
  }

  function getSelectedUrlsMessage(urlCount) {
    if (urlCount === 0) {
      return t('new_chatbot_no_urls');
    } else if (urlCount === 1) {
      return t('new_chatbot_1_url');
    } else {
      return `${urlCount} ` + t('new_chatbot_n_urls');
    }
  }

  function getAllowedDomainsMessage(domainCount) {
    if (domainCount === 0) {
      return t('new_chatbot_no_domains');
    } else if (domainCount === 1) {
      return t('new_chatbot_one_domain');
    } else {
      return `${domainCount} ` + t('new_chatbot_n_domains');
    }
  }

  function handleClickPrevious() {
    setConfigStep((configStep - 1 + 4) % 4);
  }

  async function handleClickNext() {
    if (configStep === 0) {
      setConfigStep((configStep + 1) % 4);
    } else if (configStep === 1) {
      var instructionsTooLong = false;
      var instructionsEmpty = false;
      var nameEmpty = false;
      // Check that instructions are no longer than 256 tokens
      setIsButtonLoading(true);
      const tokens = await countTokens(props.user?.uid, chatbotInstructions);
      setIsButtonLoading(false);
      setInstructionsTokens(tokens);
      if (tokens > 256) {
        consoleLogCustom('Instructions are longer than 256 tokens');
        instructionsTooLong = true;
      } else {
        instructionsTooLong = false;
      }
      setInstructionsTooLongWarning(instructionsTooLong);
      // Check that instructions are not empty
      if (chatbotInstructions === '') {
        consoleLogCustom('Instructions are empty');
        instructionsEmpty = true;        
      } else {
        instructionsEmpty = false;
      }
      setInstructionsEmptyWarning(instructionsEmpty);
      // Check that name is not empty
      if (chatbotName === '') {
        consoleLogCustom('Name is empty');
        nameEmpty = true;
      } else {
        nameEmpty = false;
      }
      setNameEmptyWarning(nameEmpty);
      // Only go to next step if no warnings are raised
      if (!instructionsTooLong && !instructionsEmpty && !nameEmpty) {
        setConfigStep((configStep + 1) % 4);
      }
    } else if (configStep === 2) {
      var noSources = false;
      // Check that there is at least one data source
      // TODO: Allow to use both a text source and a url source
      if (dataSourceType === 'text' && textSource === '') {
        consoleLogCustom('No text source');
        noSources = true;
      } else if (dataSourceType === 'website' && selectedUrlsCount === 0) {
        consoleLogCustom('No urls selected');
        noSources = true;
      }
      setNoSourcesWarning(noSources);
      // Only go to next step if no warnings are raised
      if (!noSources) {
        setConfigStep((configStep + 1) % 4);
      }
    } else if (configStep === 3) {
      var noDomains = false;
      // Check that there is at least one allowed domain
      if (allowedDomainsCount === 0) {
        consoleLogCustom('No allowed domains');
        noDomains = true;
      }
      setNoDomainsWarning(noDomains);
      // Only go to next step if no warnings are raised
      if (!noDomains) {
        // Update (or create) chatbot
        handleUpdateChatbot();
      }
    }
  }

  function handleChatbotNameChanged(event) {
    setChatbotName(event.target.value);
  }

  function handleChatbotDescriptionChanged(event) {
    setChatbotDescription(event.target.value);
  }

  function handleChatbotInstructionsChanged(event) {
    setChatbotInstructions(event.target.value);
  }

  function handleChatbotLanguageModelChanged(event) {
    setChatbotLanguageModel(event.target.value);
  }

  function handleChatbotLanguageChanged(event) {
    setChatbotLanguage(event.target.value);
  }

  function handleTextSourceChanged(event) {
    setTextSource(event.target.value);
  }

  function handleTargetUrlChanged(event) {
    setTargetUrl(event.target.value);
    setFilteredUrls(urls.filter(site => site.get('url').startsWith(event.target.value)));
  }

  function handleSitemapUrlChanged(event) {
    setSitemapUrl(event.target.value);
  }

  function handleManualUrlChanged(event) {
    setManualUrl(event.target.value);
  }

  function handleAllowedDomainChanged(event) {
    setAllowedDomain(event.target.value);
  }

  function handleGlobalCheckboxChanged(event) {
    // update all urls in the filtered list
    setFilteredUrls(filteredUrls.map(site => site.set('selected', event.target.checked)));
    // also update those urls in the complete list
    setUrls(urls.map(site => {
      if (site.get('url').startsWith(targetUrl)) {
        site.set('selected', event.target.checked);
      }
      return site;
    }));
    setGlobalCheckboxChecked(event.target.checked);
  }

  async function handleUpdateChatbot() {
    setIsButtonLoading(true);
    consoleLogCustom('Language: ', chatbotLanguage);
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(getBackendUrl() + "/update-chatbot", {
      method: "POST",
      body: JSON.stringify({
        chatbotId: props.new ? null : searchParams.get('cid'),
        chatbotName: chatbotName,
        chatbotDescription: chatbotDescription,
        chatbotInstructions: chatbotInstructions,
        chatbotLanguageModel: chatbotLanguageModel,
        chatbotLanguage: chatbotLanguage,
        dataSourceType: dataSourceType,
        textSource: textSource,
        selectedUrls: selectedUrls,
        allowedDomains: allowedDomains,
        chatbotType: chatbotType,
        avatarIdentity: avatarIdentity,
        chatbotSize: chatbotType === 'avatar' ? selectedAvatarSize : selectedChatSize,
        chatbotBubble: chatbotType === 'chat' ? showBubble : null,
        chatbotColor: chatbotType === 'chat' ? selectedColor : null,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'uid': props.user?.uid,
      },
    });
    const data = await response.json();
    setIsButtonLoading(false);
    navigate('/dashboard');
  }

  async function handleClickGetUrls() {
    consoleLogCustom(targetUrl);
    consoleLogCustom(sitemapUrl);
    if (targetUrl) {
      setIsDiscoveringUrls(true);
      // get urls from sitemap
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(getBackendUrl() + "/get-urls", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'target-url': targetUrl,
          'sitemap-url': sitemapUrl,
          'uid': props.user?.uid,
        },
      });
      const data = await response.json();
      consoleLogCustom(data);
      // Update urls, an array of maps with keys 'url' and 'selected'
      setUrls(data['urls'].map(url => {
        const urlmap = new Map()
        urlmap.set('url', url)
        urlmap.set('selected', true)
        return urlmap
      }));
      const filteredData = data['urls'].filter(url => url.startsWith(targetUrl));
      setFilteredUrls(filteredData.map(url => {
        const urlmap = new Map()
        urlmap.set('url', url)
        urlmap.set('selected', true)
        return urlmap
      }));
      setIsDiscoveringUrls(false);
    } else {
      // Raise warning window
      consoleLogCustom('Please enter a target url');
    }
  }

  function handleClickAddManualUrl() {
    consoleLogCustom(manualUrl);
    // Add manual url to urls
    const urlmap = new Map()
    urlmap.set('url', manualUrl)
    urlmap.set('selected', true)
    setUrls(urls.concat([urlmap]));
    // Add manual url to filteredUrls
    setFilteredUrls(filteredUrls.concat([urlmap]));
    // Clear manual url input
    setManualUrl('');
    // Clear actual input text
    document.getElementsByName('manual-url-input')[0].value = '';
  }

  function handleClickAddAllowedDomain() {
    consoleLogCustom(getDomainFromUrl(allowedDomain));
    // Add allowed url to allowedDomains
    setAllowedDomains(allowedDomains.concat([getDomainFromUrl(allowedDomain)]));
    // Clear allowed url input
    setAllowedDomain('');
    // Clear actual input text
    document.getElementsByName('allowed-domain-input')[0].value = '';
  }

  React.useEffect(() => {
    // update selected urls when filtered urls change
    // Get all selected urls from array filteredUrls, only those that are selected
    setSelectedUrls(filteredUrls.filter(url => url.get('selected')).map(url => url.get('url')));
    setSelectedUrlsCount(filteredUrls.filter(url => url.get('selected')).length);
  }, [filteredUrls]);

  React.useEffect(() => {
    // update allowed domains count when allowed domains change
    setAllowedDomainsCount(allowedDomains.length);
  }, [allowedDomains]);

  function getAvatarUrl() {
    switch (avatarIdentity) {
      case 'female1':
        return femaleAvatar1;
      case 'female2':
        return femaleAvatar2;
      case 'female3':
        return femaleAvatar3;
      case 'male1':
        return maleAvatar1;
      case 'male2':
        return maleAvatar2;
      case 'male3':
        return maleAvatar3;
      default:
        return femaleAvatar1;
    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='py-8 bg-blueGray-100'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap items-center justify-between -mx-4'>
              <div className='w-full md:w-auto px-4 mb-10 md:mb-0'>
                <div className='flex flex-wrap items-center'>
                  <img
                    className='mb-6 md:mb-0 md:mr-10'
                    src='uinel-assets/images/dashboard-navigations/icon1.svg'
                    alt='Icon representing a chatbot'
                  />
                  <div className='w-full md:w-auto'>
                    <h2 className='text-5xl md:text-6xl font-heading font-bold leading-relaxed'>
                      {props.new ? t('new_chatbot_title') : t('new_chatbot_title_update')}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-auto px-4'>
                <div className='flex items-center'>
                  <Link
                    className='inline-block py-4 px-8 text-lg leading-6 text-white font-medium tracking-tighter font-heading text-center bg-red-500 hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-xl'
                    to='/dashboard'
                  >
                    {t('new_chatbot_cancel')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-8 bg-blueGray-50'>
          <div className='container px-4 mx-auto'>
            <div className='p-7 pb-16 pt-12 bg-blueGray-100 rounded-5xl'>
              <div className='flex w-full'>
                <h2 className='mb-9 px-10 text-3xl font-heading font-medium'>
                  {getNameOfConfigStep(configStep)}
                </h2>
                <div className='flex'>
                  {configStep != 0 && (
                    <div className='px-10'>
                      <button
                        onClick={handleClickPrevious}
                        className='block py-4 px-4 w-full leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
                      >
                        {t('new_chatbot_previous')}
                      </button>
                    </div>
                  )}
                  <div className='px-10' >
                    <button
                      onClick={handleClickNext}
                      className={configStep != 3 ?
                        'flex py-4 px-7 w-full leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl' :
                        'flex py-4 px-7 w-full leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-green-600 focus:ring-opacity-50 rounded-xl'}
                    >
                      {isButtonLoading && (
                        <svg className="text-white w-4 h-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                          <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                        </svg>
                      )}
                      {configStep != 3 ? t('new_chatbot_next') : props.new ? (isButtonLoading ? t('new_chatbot_creating') : t('new_chatbot_create')) : (isButtonLoading ? t('new_chatbot_updating') : t('new_chatbot_update'))}
                    </button>
                  </div>
                </div>
              </div>
              {configStep == 0 && (
                <div>
                  <div className='px-9 mb-4'>
                    <div className='border-t border-gray-100'>
                      <button
                        onClick={() => setChatbotType('avatar')}
                        className={chatbotType === 'avatar' ?
                          'inline-block py-2 w-32 text-indigo-500 hover:text-indigo-600 font-heading font-medium border-t-2 border-indigo-500' :
                          'inline-block py-2 w-32 text-gray-300 hover:text-gray-400 font-heading font-medium'}
                      >
                        {t('new_chatbot_avatar')}
                      </button>
                      <button
                        onClick={() => setChatbotType('chat')}
                        className={chatbotType === 'chat' ?
                          'inline-block py-2 w-32 text-indigo-500 hover:text-indigo-600 font-heading font-medium border-t-2 border-indigo-500' :
                          'inline-block py-2 w-32 text-gray-300 hover:text-gray-400 font-heading font-medium'}
                      >
                        {t('new_chatbot_chatbot')}
                      </button>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className={'bg-transparent left-0 top-0 ' + chatbotType == 'chat' ? selectedChatSize : selectedAvatarSize} >
                      {chatbotType === 'chat' && (
                        <BubbleChat
                          className='ml-9'
                          user={props.user}
                          chatbotId={'zI4jYhNFqimHuzZW2p6S'}
                          size={getChatSize(selectedChatSize)}
                          language={i18n.language}
                          showBubble={showBubble}
                          dashboardMode={true}
                          parent='chattier.dev'
                          color={selectedColor}
                        />
                      )}
                      {chatbotType === 'avatar' && (
                        <AvatarView
                          name='avatar-view'
                          avatarUrl={getAvatarUrl()}
                          showIFrame={false} user={props.user}
                          chatbotId={'zI4jYhNFqimHuzZW2p6S'}
                          size={getAvatarSizeInPixels(selectedAvatarSize)}
                          language={i18n.language}
                          showBubble={null} // Not used for avatars
                          dashboardMode={null} // Not used for avatars
                          parent='chattier.dev'
                          aid={avatarIdentity}
                        />
                      )}
                    </div>
                    {chatbotType === 'avatar' && (
                      <div className='' >
                        <h1 className='pl-9 mt-1 mb-2 text-lg font-bold'>
                          {t('new_chatbot_select_avatar')}
                        </h1>
                        <div className='ml-9 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                          <button
                            onClick={() => {
                              setAvatarIdentity('female1');
                            }}
                            className={
                              (avatarIdentity === 'female1' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={femaleAvatar1Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                          <button
                            onClick={() => {
                              setAvatarIdentity('female2');
                            }}
                            className={
                              (avatarIdentity === 'female2' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={femaleAvatar2Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                          <button
                            onClick={() => {
                              setAvatarIdentity('female3');
                            }}
                            className={
                              (avatarIdentity === 'female3' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={femaleAvatar3Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                          <button
                            onClick={() => {
                              setAvatarIdentity('male1');
                            }}
                            className={
                              (avatarIdentity === 'male1' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={maleAvatar1Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                          <button
                            onClick={() => {
                              setAvatarIdentity('male2');
                            }}
                            className={
                              (avatarIdentity === 'male2' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={maleAvatar2Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                          <button
                            onClick={() => {
                              setAvatarIdentity('male3');
                            }}
                            className={
                              (avatarIdentity === 'male3' ? 'border-4' : 'border-2') +
                              ' border-solid border-indigo-600 rounded-2xl'}>
                            <img
                              className='w-48 h-48'
                              src={maleAvatar3Image}
                              alt="3D avatar image">
                            </img>
                          </button>
                        </div>
                        <h1 className='pl-9 mt-4 mb-2 text-lg font-bold'>
                          {t('new_chatbot_select_size')}
                        </h1>
                        <div className='mb-3 ml-9 grid grid-cols-1 gap-0'>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 1'
                              checked={selectedAvatarSize === 'small'}
                              onChange={() => setSelectedAvatarSize('small')}
                            />
                            <span className='ml-3'>{t('new_chatbot_small')}</span>
                          </label>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 2'
                              checked={selectedAvatarSize === 'medium'}
                              onChange={() => setSelectedAvatarSize('medium')}
                            />
                            <span className='ml-3'>{t('new_chatbot_medium')}</span>
                          </label>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 3'
                              checked={selectedAvatarSize === 'large'}
                              onChange={() => setSelectedAvatarSize('large')}
                            />
                            <span className='ml-3'>{t('new_chatbot_large')}</span>
                          </label>
                        </div>
                      </div>
                    )}
                    {chatbotType === 'chat' && (
                      <div>

                        <h1 className='pl-9 mt-1 mb-2 text-lg font-bold'>
                          {t('new_chatbot_select_color')}
                        </h1>
                        <div className='ml-9 mb-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                          <button
                            onClick={() => { setSelectedColor('indigo') }}
                            className='flex items-center justify-center w-24 h-24 bg-indigo-400 rounded-2xl'>
                            {selectedColor === 'indigo' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                          <button
                            onClick={() => { setSelectedColor('blue') }}
                            className='flex items-center justify-center w-24 h-24 bg-blue-400 rounded-2xl'>
                            {selectedColor === 'blue' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                          <button
                            onClick={() => { setSelectedColor('green') }}
                            className='flex items-center justify-center w-24 h-24 bg-green-400 rounded-2xl'>
                            {selectedColor === 'green' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                          <button
                            onClick={() => { setSelectedColor('yellow') }}
                            className='flex items-center justify-center w-24 h-24 bg-yellow-400 rounded-2xl'>
                            {selectedColor === 'yellow' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                          <button
                            onClick={() => { setSelectedColor('orange') }}
                            className='flex items-center justify-center w-24 h-24 bg-orange-400 rounded-2xl'>
                            {selectedColor === 'orange' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                          <button
                            onClick={() => { setSelectedColor('red') }}
                            className='flex items-center justify-center w-24 h-24 bg-red-400 rounded-2xl'>
                            {selectedColor === 'red' && (
                              <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                                <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            )}
                          </button>
                        </div>
                        <h1 className='pl-9 mt-4 mb-2 text-lg font-bold'>
                          {t('new_chatbot_select_size')}
                        </h1>
                        <div className='mb-3 ml-9 grid grid-cols-1 gap-0'>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 1'
                              checked={selectedChatSize === 'small'}
                              onChange={() => setSelectedChatSize('small')}
                            />
                            <span className='ml-3'>{t('new_chatbot_small')}</span>
                          </label>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 2'
                              checked={selectedChatSize === 'medium'}
                              onChange={() => setSelectedChatSize('medium')}
                            />
                            <span className='ml-3'>{t('new_chatbot_medium')}</span>
                          </label>
                          <label>
                            <input
                              className='ml-3'
                              type='radio'
                              name='inline-radio'
                              defaultValue='option 3'
                              checked={selectedChatSize === 'large'}
                              onChange={() => setSelectedChatSize('large')}
                            />
                            <span className='ml-3'>{t('new_chatbot_large')}</span>
                          </label>
                        </div>
                        <h1 className='pl-9 mt-4 mb-2 text-lg font-bold'>
                          {t('new_chatbot_minimization')}
                        </h1>
                        <div className='flex' >
                          <input
                            className='ml-9 w-6 h-6 accent-indigo-600 bg-white border-2 border-darkbluegray-50 rounded-lg'
                            type='checkbox'
                            name='bubble-checkbox'
                            id='bubble-checkbox'
                            checked={showBubble}
                            onChange={() => setShowBubble(!showBubble)}
                          />
                          <span className='ml-2'>
                            {t('new_chatbot_minimization_description')}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {configStep == 1 && (
                <div className='px-9 mb-8 w-[40rem]'>
                  {nameEmptyWarning && (
                    <div className="flex mb-1">
                      <WarningIcon />
                      <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_name_empty_warning')}</p>
                    </div>
                  )}
                  <div>
                    <span>{t('new_chatbot_name')}</span>
                    <input
                      className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                      type='text'
                      placeholder={t('new_chatbot_name_eg')}
                      value={chatbotName}
                      onChange={handleChatbotNameChanged}
                    />
                  </div>
                  <div>
                    <span>{t('new_chatbot_description')}</span>
                    <textarea
                      className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                      rows='3'
                      cols='50'
                      placeholder={t('new_chatbot_description_eg')}
                      value={chatbotDescription}
                      onChange={handleChatbotDescriptionChanged}
                    />
                  </div>
                  {instructionsTooLongWarning && (
                    <div className="flex mb-1">
                      <WarningIcon />
                      <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_instructions_too_long_warning', { tokens: instructionsTokens })}</p>
                    </div>
                  )}
                  {instructionsEmptyWarning && (
                    <div className="flex mb-1">
                      <WarningIcon />
                      <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_instructions_empty_warning')}</p>
                    </div>
                  )}
                  <div>
                    <span>{t('new_chatbot_instructions')}</span>
                    <textarea
                      className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                      rows='6'
                      cols='50'
                      placeholder={t('new_chatbot_instructions_placeholder')}
                      value={chatbotInstructions}
                      onChange={handleChatbotInstructionsChanged}
                    />
                  </div>
                  <div>
                    <span>{t('new_chatbot_language_model')}</span>
                    <select
                      name="llm-model"
                      id="llm-model"
                      className="mb-4 ml-4 w-60 h-10 rounded-xl border-2 border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                      value={chatbotLanguageModel}
                      onChange={handleChatbotLanguageModelChanged}>
                      {AvailableLanguageModels.map((model) => {
                        return (
                          <option value={model['value']} key={model['value']}>{model['name']}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div>
                    <span>{t('new_chatbot_language')}</span>
                    <select
                      name="language"
                      id="language"
                      className="ml-4 w-60 h-10 rounded-xl border-2 border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                      value={chatbotLanguage}
                      onChange={handleChatbotLanguageChanged}>
                      {AvailableLanguages.map((language) => {
                        return (
                          <option value={language['value']} key={language['value']}>{t('languages_' + language['value'].replace('-', '_'), { rgn: '(US)' })}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              )}
              {configStep == 2 && (
                <div>
                  <div className='px-9 mb-4'>
                    <div className='border-t border-gray-100'>
                      <button
                        onClick={() => setDataSourceType('text')}
                        className={dataSourceType === 'text' ?
                          'inline-block py-2 w-32 text-indigo-500 hover:text-indigo-600 font-heading font-medium border-t-2 border-indigo-500' :
                          'inline-block py-2 w-32 text-gray-300 hover:text-gray-400 font-heading font-medium'}
                      >
                        {t('new_chatbot_text')}
                      </button>
                      <button
                        onClick={() => setDataSourceType('website')}
                        className={dataSourceType === 'website' ?
                          'inline-block py-2 w-32 text-indigo-500 hover:text-indigo-600 font-heading font-medium border-t-2 border-indigo-500' :
                          'inline-block py-2 w-32 text-gray-300 hover:text-gray-400 font-heading font-medium'}
                      >
                        {t('new_chatbot_website')}
                      </button>
                    </div>
                  </div>
                  {noSourcesWarning && (
                    <div className="flex mb-1">
                      <WarningIcon />
                      <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_no_sources_warning')}</p>
                    </div>
                  )}
                  {dataSourceType === 'text' && (
                    <div>
                      <h1 className='mt-3 mb-4 text-lg font-bold'>
                        {t('new_chatbot_enter_text')}
                      </h1>
                      <textarea
                        className='w-full mb-3 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                        rows='15'
                        cols='50'
                        placeholder={t('new_chatbot_enter_text_description')}
                        value={textSource}
                        onChange={handleTextSourceChanged}
                      />
                    </div>
                  )}
                  {dataSourceType === 'website' && (
                    <div>
                      <div className='px-9 mb-4 w-[40rem]'>
                        <div className='mb-3 flex'>
                          <span className='text-indigo-500'>
                            {getSelectedUrlsMessage(selectedUrlsCount)}
                          </span>
                        </div>
                        <div className='border-t border-gray-100' />
                        <div className='mb-1'>
                          <div className='flex'>
                            <h1 className='mt-3 mb-4 text-lg font-bold'>
                              {t('new_chatbot_url_discovery')}
                            </h1>
                            <div className='mt-2 w-full md:w-1/2 max-w-max md:ml-auto'>
                              <button
                                onClick={handleClickGetUrls}
                                className='flex py-3 px-5 w-full leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 rounded-xl'
                              >
                                {isDiscoveringUrls && (
                                  <svg className="text-white w-4 h-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                                  </svg>
                                )}
                                {t('new_chatbot_url_discovery_button')}
                              </button>
                            </div>
                          </div>
                          <div>
                            <span>{t('new_chatbot_urls_starting')}</span>
                            <input
                              className='w-full mb-4 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                              type='text'
                              placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/blog'}
                              value={targetUrl}
                              onChange={handleTargetUrlChanged}
                            />
                          </div>
                          <span>{t('new_chatbot_sitemap')}</span>
                          <div>
                            <input
                              className='w-full mb-1 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                              type='text'
                              placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/sitemap.xml'}
                              value={sitemapUrl}
                              onChange={handleSitemapUrlChanged}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='border-t border-gray-100' />
                        <h1 className='px-9 mt-3 text-lg font-bold'>
                          {t('new_chatbot_manual_entry')}
                        </h1>
                        <div className='flex'>
                          <div className='px-9 mt-3 w-[40rem]'>
                            <div>
                              <input
                                name='manual-url-input'
                                className='w-full mb-1 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                                type='text'
                                placeholder={t('new_chatbot_eg') + ' https://www.mycompany.com/blog/example-article'}
                                value={manualUrl}
                                onChange={handleManualUrlChanged}
                              />
                            </div>
                          </div>
                          <div className='mt-3'>
                            <button
                              onClick={handleClickAddManualUrl}
                              className='block py-4 px-7 w-30 leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
                            >
                              {t('new_chatbot_add_url')}
                            </button>
                          </div>
                        </div>
                        <div className='mt-3 border-t border-gray-100' />
                        <div className='overflow-x-auto'>
                          <div className='inline-block min-w-full overflow-hidden'>
                            <table className='table-auto w-full'>
                              <thead>
                                <tr>
                                  <th className='flex items-center pl-9 h-20 bg-blueGray-100 text-left'>
                                    <input
                                      className='w-6 h-6 mr-9 bg-white border-2 border-darkbluegray-50 rounded-md'
                                      type='checkbox'
                                      name='url-checkbox'
                                      id='url-checkbox'
                                      checked={globalCheckboxChecked}
                                      onChange={handleGlobalCheckboxChanged}
                                    />
                                    <a
                                      className='inline-block mr-4 text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'
                                      href='#'
                                    >
                                      {t('new_chatbot_url')}
                                    </a>
                                    <div>
                                      <svg
                                        className='my-1'
                                        width={7}
                                        height={4}
                                        viewBox='0 0 7 4'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M0.898704 3.84541C0.692525 4.05153 0.359292 4.05153 0.154634 3.84541C-0.0507836 3.6393 -0.0523052 3.30629 0.154635 3.10017L3.12787 0.154586C3.33329 -0.0515278 3.66576 -0.0515278 3.8727 0.154586L6.84594 3.10017C7.05135 3.30552 7.05135 3.6393 6.84594 3.84541C6.63976 4.05153 6.30652 4.05153 6.10187 3.84541L3.49915 1.42973L0.898704 3.84541Z'
                                          fill='#9E9FA0'
                                        />
                                      </svg>
                                      <svg
                                        className='my-1'
                                        width={7}
                                        height={4}
                                        viewBox='0 0 7 4'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M6.10129 0.154586C6.30747 -0.0515286 6.64071 -0.0515286 6.84537 0.154586C7.05078 0.3607 7.0523 0.693713 6.84537 0.899827L3.87213 3.84541C3.66671 4.05153 3.33424 4.05153 3.1273 3.84541L0.154064 0.899827C-0.0513545 0.694482 -0.0513545 0.3607 0.154064 0.154586C0.360242 -0.0515286 0.693476 -0.0515286 0.898133 0.154586L3.50085 2.57027L6.10129 0.154586Z'
                                          fill='#9E9FA0'
                                        />
                                      </svg>
                                    </div>
                                  </th>
                                  <th className='p-5 h-20 bg-blueGray-100'>
                                    {/* <div className='flex items-center justify-center'>
                                  <a
                                    className='inline-block mr-4 text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'
                                    href='#'
                                  >
                                    Characters
                                  </a>
                                  <div>
                                    <svg
                                      className='my-1'
                                      width={7}
                                      height={4}
                                      viewBox='0 0 7 4'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M0.898704 3.84541C0.692525 4.05153 0.359292 4.05153 0.154634 3.84541C-0.0507836 3.6393 -0.0523052 3.30629 0.154635 3.10017L3.12787 0.154586C3.33329 -0.0515278 3.66576 -0.0515278 3.8727 0.154586L6.84594 3.10017C7.05135 3.30552 7.05135 3.6393 6.84594 3.84541C6.63976 4.05153 6.30652 4.05153 6.10187 3.84541L3.49915 1.42973L0.898704 3.84541Z'
                                        fill='#9E9FA0'
                                      />
                                    </svg>
                                    <svg
                                      className='my-1'
                                      width={7}
                                      height={4}
                                      viewBox='0 0 7 4'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M6.10129 0.154586C6.30747 -0.0515286 6.64071 -0.0515286 6.84537 0.154586C7.05078 0.3607 7.0523 0.693713 6.84537 0.899827L3.87213 3.84541C3.66671 4.05153 3.33424 4.05153 3.1273 3.84541L0.154064 0.899827C-0.0513545 0.694482 -0.0513545 0.3607 0.154064 0.154586C0.360242 -0.0515286 0.693476 -0.0515286 0.898133 0.154586L3.50085 2.57027L6.10129 0.154586Z'
                                        fill='#9E9FA0'
                                      />
                                    </svg>
                                  </div>
                                </div> */}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredUrls.map((site, i) => {
                                  return (
                                    <tr key={`key_${i}`}>
                                      <td className='p-0'>
                                        <div className={(i % 2 == 0) ?
                                          'flex items-center pl-9 h-10 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-xl rounded-bl-xl' :
                                          'flex items-center pl-9 h-10 bg-white rounded-tl-xl rounded-bl-xl'}>
                                          <input
                                            className='w-6 h-6 mr-9 bg-white border-2 border-darkbluegray-50 rounded-md'
                                            type='checkbox'
                                            name={'url-checkbox-' + i.toString()}
                                            id={'url-checkbox-' + i.toString()}
                                            checked={site.get('selected')}
                                            onChange={(e) => {
                                              site.set('selected', !site.get('selected'));
                                              setFilteredUrls(filteredUrls.toSpliced(i, 1, site)); // Replace the urlMap at index i with the new urlMap
                                            }}
                                          />
                                          <span className='text-md font-heading font-medium'>
                                            {site.get('url')}
                                          </span>
                                        </div>
                                      </td>
                                      <td className='p-0'>
                                        <div className={(i % 2 == 0) ?
                                          'flex items-center justify-center p-5 h-10 text-center bg-blueGray-50 border-t border-b border-r border-gray-100 rounded-tr-xl rounded-br-xl' :
                                          'flex items-center justify-center p-5 h-10 text-center bg-white rounded-tr-xl rounded-br-xl'}>
                                          <span className='text-md text-darkBlueGray-400 font-heading'>
                                            {''}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {configStep == 3 && (
                <div>
                  <div className='px-9 mb-3 flex'>
                    <span className='text-indigo-500'>
                      {getAllowedDomainsMessage(allowedDomainsCount)}
                    </span>
                  </div>
                  {noDomainsWarning && (
                    <div className="flex mb-1">
                      <WarningIcon />
                      <p className="px-2 text-gray-500 text-sm">{t('new_chatbot_no_domains_warning')}</p>
                    </div>
                  )}
                  <div className='border-t border-gray-100' />
                  <h1 className='px-9 mt-3 text-lg font-bold'>
                    {t('new_chatbot_install')}
                  </h1>
                  <div className='flex'>
                    <div className='px-9 mt-3 w-[40rem]'>
                      <div>
                        <input
                          name='allowed-domain-input'
                          className='w-full mb-1 py-2 px-4 text-lg border-2 border-purple-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                          type='text'
                          placeholder={t('new_chatbot_eg') + ' mycompany.com'}
                          onChange={handleAllowedDomainChanged}
                        />
                      </div>
                    </div>
                    <div className='mt-3'>
                      <button
                        onClick={handleClickAddAllowedDomain}
                        className='block py-4 px-7 w-30 leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
                      >
                        {t('new_chatbot_add_domain')}
                      </button>
                    </div>
                  </div>
                  <div className='mt-3 border-t border-gray-100' />
                  <div className='overflow-x-auto'>
                    <div className='inline-block min-w-full overflow-hidden'>
                      <table className='table-auto w-full'>
                        <thead>
                          <tr>
                            <th className='flex items-center pl-9 h-20 bg-blueGray-100 text-left'>
                              <a
                                className='inline-block mr-4 text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'
                                href='#'
                              >
                                {t('new_chatbot_domain')}
                              </a>
                              <div>
                                <svg
                                  className='my-1'
                                  width={7}
                                  height={4}
                                  viewBox='0 0 7 4'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M0.898704 3.84541C0.692525 4.05153 0.359292 4.05153 0.154634 3.84541C-0.0507836 3.6393 -0.0523052 3.30629 0.154635 3.10017L3.12787 0.154586C3.33329 -0.0515278 3.66576 -0.0515278 3.8727 0.154586L6.84594 3.10017C7.05135 3.30552 7.05135 3.6393 6.84594 3.84541C6.63976 4.05153 6.30652 4.05153 6.10187 3.84541L3.49915 1.42973L0.898704 3.84541Z'
                                    fill='#9E9FA0'
                                  />
                                </svg>
                                <svg
                                  className='my-1'
                                  width={7}
                                  height={4}
                                  viewBox='0 0 7 4'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M6.10129 0.154586C6.30747 -0.0515286 6.64071 -0.0515286 6.84537 0.154586C7.05078 0.3607 7.0523 0.693713 6.84537 0.899827L3.87213 3.84541C3.66671 4.05153 3.33424 4.05153 3.1273 3.84541L0.154064 0.899827C-0.0513545 0.694482 -0.0513545 0.3607 0.154064 0.154586C0.360242 -0.0515286 0.693476 -0.0515286 0.898133 0.154586L3.50085 2.57027L6.10129 0.154586Z'
                                    fill='#9E9FA0'
                                  />
                                </svg>
                              </div>
                            </th>
                            <th className='p-5 h-20 bg-blueGray-100'>
                              {/* <div className='flex items-center justify-center'>
                                <a
                                  className='inline-block mr-4 text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'
                                  href='#'
                                >
                                  Characters
                                </a>
                                <div>
                                  <svg
                                    className='my-1'
                                    width={7}
                                    height={4}
                                    viewBox='0 0 7 4'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M0.898704 3.84541C0.692525 4.05153 0.359292 4.05153 0.154634 3.84541C-0.0507836 3.6393 -0.0523052 3.30629 0.154635 3.10017L3.12787 0.154586C3.33329 -0.0515278 3.66576 -0.0515278 3.8727 0.154586L6.84594 3.10017C7.05135 3.30552 7.05135 3.6393 6.84594 3.84541C6.63976 4.05153 6.30652 4.05153 6.10187 3.84541L3.49915 1.42973L0.898704 3.84541Z'
                                      fill='#9E9FA0'
                                    />
                                  </svg>
                                  <svg
                                    className='my-1'
                                    width={7}
                                    height={4}
                                    viewBox='0 0 7 4'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M6.10129 0.154586C6.30747 -0.0515286 6.64071 -0.0515286 6.84537 0.154586C7.05078 0.3607 7.0523 0.693713 6.84537 0.899827L3.87213 3.84541C3.66671 4.05153 3.33424 4.05153 3.1273 3.84541L0.154064 0.899827C-0.0513545 0.694482 -0.0513545 0.3607 0.154064 0.154586C0.360242 -0.0515286 0.693476 -0.0515286 0.898133 0.154586L3.50085 2.57027L6.10129 0.154586Z'
                                      fill='#9E9FA0'
                                    />
                                  </svg>
                                </div>
                              </div> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allowedDomains.map((url, i) => {
                            return (
                              <tr key={`key_${i}`}>
                                <td className='p-0'>
                                  <div className={(i % 2 == 0) ?
                                    'flex items-center pl-9 h-10 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-xl rounded-bl-xl' :
                                    'flex items-center pl-9 h-10 bg-white rounded-tl-xl rounded-bl-xl'}>
                                    <span className='text-md font-heading font-medium'>
                                      {url}
                                    </span>
                                  </div>
                                </td>
                                <td className='p-0'>
                                  <div className={(i % 2 == 0) ?
                                    'flex items-center justify-center p-5 h-10 text-center bg-blueGray-50 border-t border-b border-r border-gray-100 rounded-tr-xl rounded-br-xl' :
                                    'flex items-center justify-center p-5 h-10 text-center bg-white rounded-tr-xl rounded-br-xl'}>
                                    <span className='text-md text-darkBlueGray-400 font-heading'>
                                      {''}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment >
  );
}

