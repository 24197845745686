import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Routes,
  Route,
  useSearchParams,
  BrowserRouter
} from "react-router-dom"
import BubbleChat from '../components/BubbleChat';
import AvatarView from '../components/AvatarView';
import { getDomainFromUrl } from '../components/Domain';
import { query } from 'firebase/firestore';
import { consoleLogCustom, getAvatarSizeInPixels, getAvatarUrl, getChatOrAvatarSize, getChatSize } from '../components/Utils';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ChatbotIframe(props) {

  const [queryParameters] = useSearchParams();

  // // Execute code when component is mounted
  // React.useEffect(() => {
  //   consoleLogCustom('ChatbotIframe mounted');
  //   consoleLogCustom('queryParameters: ', queryParameters);
  // }, []);

  function getChatbotSize() {
    const size = queryParameters.get("size");
    switch (queryParameters.get("type")) {
      case 'chat':
        return getChatSize(size);
      case 'avatar':
        return getAvatarSizeInPixels(size);
      default:
        return getChatSize(size);
    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <div className={'bg-transparent left-0 top-0' + getChatbotSize()} >
          {queryParameters.get("type") === 'chat' && (
            <BubbleChat
              user={props.user}
              chatbotId={queryParameters.get("cid")}
              size={getChatbotSize()}
              language={queryParameters.get("language")}
              showBubble={queryParameters.get("bubble") === 'true'}
              parent={getDomainFromUrl(document.referrer)} // https://stackoverflow.com/a/69049876. Alternatively: window.location.ancestorOrigins[0] for chromium based browsers
              color={queryParameters.get("color")}
            />
          )}
          {queryParameters.get("type") === 'avatar' && (
            <AvatarView avatarUrl={getAvatarUrl(queryParameters.get("identity"))}
              showIFrame={false} user={props.user}
              chatbotId={queryParameters.get("cid")}
              size={getChatbotSize()}
              language={queryParameters.get("language")}
              showBubble={queryParameters.get("bubble") === 'true'}
              parent={getDomainFromUrl(document.referrer)} // https://stackoverflow.com/a/69049876. Alternatively: window.location.ancestorOrigins[0] for chromium based browsers
              aid={queryParameters.get("identity")}
            />
          )}
        </div>
      </>
    </React.Fragment>
  );
}

ChatbotIframe.defaultProps = {
  showBubble: false,
  color: 'indigo',
};

