import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import chattierLogo from '../assets/uinel-assets/logos/chattier_logo_white.svg';
import { ReducedAvailableLanguages } from './AvailableLanguages';
import { useTranslation } from 'react-i18next';
import { consoleLogCustom } from './Utils';

function Navbar(props) {

  const [userBarOpen, setUserBarOpen] = React.useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = React.useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function handleClickUser() {
    if (props.user?.email) {
      setUserBarOpen(!userBarOpen);
    } else {
      navigate('/signin');
    }
  }

  function handleClickSignOut() {
    setUserBarOpen(false);
    props.logout();
  }

  function handleClickOpenLanguageMenu() {
    setLanguageMenuOpen(!languageMenuOpen);
    // Get element by id
    const languageMenu = document.getElementById('language-menu');
    // Toggle menu visibility by adding hidden class
    if (languageMenuOpen) {
      languageMenu.classList.add('hidden');
    } else {
      languageMenu.classList.remove('hidden');
    }
    // console.log(Rewardful.referral); // For testing
  }

  return (
    <section className='text-gray-50 font-heading font-medium relative bg-indigo-700'>
      <nav className='flex justify-between px-6 lg:px-12 py-8'>
        <div className='flex w-full items-center justify-between'>
          <div className='flex items-center justify-left'>
            <Link to='/'>
              <img
                className='h-12'
                src={chattierLogo}
                alt='Chattier logo'
              />
            </Link>
            <ul className='hidden xl:flex px-4 ml-16'>
              <li className='mr-10'>
                <Link to='/pricing' className='text-white'>
                  {t('navbar_pricing')}
                </Link>
              </li>
              <li className='mr-10'>
                <Link to='/contact' className='text-white'>
                  {t('navbar_contact')}
                </Link>
              </li>
              <li className='mr-10'>
                <Link to='/about' className='text-white'>
                  {t('navbar_about')}
                </Link>
              </li>
              <li className='mr-10'>
                <button
                  onClick={() => { window.location.href = 'https://chattier.getrewardful.com/signup' }}
                  className='text-white'>
                  {t('navbar_affiliate')}
                </button>
              </li>
            </ul>
          </div>
          <div className='hidden xl:flex items-center'>
            <button
              onClick={handleClickUser}
              className='flex items-center text-white' >
              <span>{props.user?.email ? props.user?.email : t('navbar_signin')}</span>
              {props.user?.email && (
                <div className='ml-4 relative max-w-max'>
                  <img
                    className='w-10 h-10'
                    src='uinel-assets/elements/pricing/circle4.svg'
                    alt='Coloured circle icon with user initial inside'
                  />
                  <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl capitalize'>
                    {props.user?.email[0]}
                  </span>
                </div>
              )}
              {props.user?.email && (
                <img
                  className='ml-4'
                  src='uinel-assets/elements/navigations/arrow-down-white.svg'
                  alt='Arrow icon for opening and closing user menu'
                />
              )}
            </button>
            {props.user?.email && (
              <div className='w-px h-8 bg-white mx-9 bg-opacity-20' />
            )}
            {props.user?.email && (
              <Link
                to='/dashboard'
                className='uppercase text-white text-sm font-body font-bold border-2 border-white border-opacity-30 rounded-full py-3 px-5 tracking-wide hover:border-white'>
                <span className='block mt-px'>{t('navbar_my_chatbots')}</span>
              </Link>
            )}
            <div className='w-px h-8 bg-white mx-9 bg-opacity-20' />
            {/* Render a dropdown menu when the SVG logo is clicked */}
            {/* https://tailwindui.com/components/application-ui/elements/dropdowns */}
            <button
              type="button"
              className="w-auto justify-center bg-transparent"
              id="language-menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={handleClickOpenLanguageMenu}>
              <svg className="text-white w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
              </svg>
            </button>
            <div
              className="hidden absolute right-2 top-20 z-[600] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              id="language-menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1">
              <div className="py-1" role="none">
                {ReducedAvailableLanguages.map((language, index) => (
                  <button
                    key={index}
                    onClick={() => { i18n.changeLanguage(language['value']); handleClickOpenLanguageMenu(); consoleLogCustom(i18n.language) }}
                    className="flex text-gray-700 px-4 py-2 w-full text-sm text-left hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${index}`}>
                    <img
                      className='w-7 mr-2'
                      src={language['flag']}
                      alt='Flag icon representing the language'
                    />
                    {t('languages_' + language['value'])}
                    {/* if language is selected, add a checkmark icon */}
                    {i18n.language.split('-')[0] === language['value'] && (
                      <svg className="ml-1 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <button className='navbar-burger self-center xl:hidden focus:outline-none'>
          <svg
            width={25}
            height={16}
            viewBox='0 0 25 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width={25} height={2} fill='currentColor' />
            <rect y={14} width={25} height={2} fill='currentColor' />
          </svg>
        </button>
      </nav>
      {userBarOpen && (
        <div className='navbar-menu fixed top-0 right-0 bottom-0 w-5/6 max-w-sm z-50'>
          <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
          <nav className='relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto'>
            <div className='flex items-center mt-10 mb-10 px-10'>
              <div className='ml-4 relative max-w-max'>
                <img
                  className='w-10 h-10'
                  src='uinel-assets/elements/pricing/circle4.svg'
                  alt='Coloured circle icon with user initial inside'
                />
                <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl capitalize'>
                  {props.user ? props.user?.email[0] : 'User'}
                </span>
              </div>
              <span className='ml-3 text-darkBlueGray-400 text-ellipsis' >{props.user ? props.user?.email : 'User'}</span>
            </div>
            <div>
              <ul>
                <li className='mb-1 px-10'>
                  <Link
                    to='/profile'
                    className='flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl'
                  >
                    <span>{t('navbar_profile')}</span>
                  </Link>
                </li>
                <li className='mb-1 px-10'>
                  <Link to='/dashboard' className='block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl'>
                    {t('navbar_chatbots')}
                  </Link>
                </li>
                <li className='mb-1 px-10'>
                  <Link className='block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl'>
                    <button onClick={handleClickSignOut}>
                      {t('navbar_signout')}
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className='mt-auto px-10'>
              <p className='mt-6 mb-4 text-center'>
                <span className='text-sm text-darkBlueGray-400'>
                  2023 © Chattier. All rights reserved.
                </span>
              </p>
            </div>
          </nav>
          <button onClick={handleClickUser} className='navbar-close absolute top-5 p-6 right-5'>
            <div className='absolute top-3'>
              <span className='absolute w-px h-6 bg-black transform -rotate-45' />
              <span className='absolute w-px h-6 bg-black transform rotate-45' />
            </div>
          </button>
        </div>
      )}
    </section>
  );
}

export default Navbar;