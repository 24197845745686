import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Faq from '../components/Faq';
import { useTranslation } from 'react-i18next';
import { MessageCredits, renderPricesTable } from '../components/Utils';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Faqs(props) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='font-medium bg-white py-24 2xl:pt-20 2xl:pb-20 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <h2 className='font-heading text-9xl md:text-10xl xl:text-11xl leading-tight mb-16 xl:mb-20 text-center'>
              {t('about_title')}
            </h2>
            <h3 className='text-5xl md:text-6xl xl:text-7xl leading-tight mb-9 text-left ml-20 font-bold'>
              {t('about_general')}
            </h3>
            <div className='mx-20 mt-10'>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_0')}
                  answer={t('about_general_answer_0')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_1')}
                  answer={t('about_general_answer_1')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_2')}
                  answer={t('about_general_answer_2')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_3')}
                  answer={t('about_general_answer_3')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_4')}
                  answer={t('about_general_answer_4')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_5')}
                  answer={t('about_general_answer_5')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_6')}
                  answer={t('about_general_answer_6')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_7')}
                  answer={t('about_general_answer_7')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_8')}
                  answer={t('about_general_answer_8')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_9')}
                  answer={t('about_general_answer_9')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_10')}
                  answer={t('about_general_answer_10')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_11')}
                  answer={t('about_general_answer_11')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_general_question_12')}
                  answer={t('about_general_answer_12')}>
                </Faq>
              </div>
            </div>
            <h3 className='text-5xl md:text-6xl xl:text-7xl leading-tight mb-9 mt-20 text-left ml-20 font-bold'>
              {t('about_pricing')}
            </h3>
            <div className='mx-20 mt-10'>
              <div className='mb-9'>
                <Faq
                  question={t('about_pricing_question_1')}
                  answer={t('about_pricing_answer_1')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_pricing_question_2')}
                  answer={
                    t('about_pricing_answer_2',
                    {
                      ch35: MessageCredits['chat']['gpt-3.5-turbo'],
                      ch4: MessageCredits['chat']['gpt-4-turbo'],
                      av35: MessageCredits['avatar']['gpt-3.5-turbo'],
                      av4: MessageCredits['avatar']['gpt-4-turbo'],
                    })}
                  content={(
                    <div className='mt-3 w-1/2 text-md font-normal leading-6'>
                      {renderPricesTable(t)}
                    </div>
                  )}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_pricing_question_3')}
                  answer={t('about_pricing_answer_3')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_pricing_question_4')}
                  answer={t('about_pricing_answer_4')}>
                </Faq>
              </div>
              <div className='mb-9'>
                <Faq
                  question={t('about_pricing_question_5')}
                  answer={t('about_pricing_answer_5')}>
                </Faq>
              </div>
              <div className=''>
                <Faq
                  question={t('about_pricing_question_6')}
                  answer={t('about_pricing_answer_6')}>
                </Faq>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

